import React from 'react';

function HeaderComp (props, context) {
    return (
        <>
            <div className='FontsStyle'>
                <div>
                    <img src= 'https://cdn.venuemonk.com/wedding-party-banner.webp' alt='venumonk' className='bg' />            
                </div>
                <div style={{ paddingTop: '5px' }}>
                        <div style={{ fontSize:'min(6vw, 36px)'}}> Anniversary Party </div>
                        <div style={{ paddingBottom: '15', fontSize:'min(4vw, 20px)' }}>Hey, it’s your anniversary soon! <br /> Treat your better half to a surprise.
                        </div>
                </div>
         </div>

        </>
    );
  }
  
  export default HeaderComp;

  
    